export default class Account {
  constructor(
    firstname,
    lastname,
    email,
    password,
    company,
    phone,
    address,
    city,
    state,
    zip,
    country,
    referral,
    type,
    license,
    licensesAmount,
    license_id,
    license_price_id,
    licence_quantity
  ) {
    this.firstname = firstname;
    this.lastname = lastname;
    this.email = email;
    this.password = password;
    this.company = company;
    this.phone = phone;
    this.address = address;
    this.city = city;
    this.state = state;
    this.zip = zip;
    this.country = country;
    this.referral = referral;
    this.type = type;
    this.license = license;
    this.licensesAmount = licensesAmount ? licensesAmount : 1;
    this.license_id = license_id;
    this.license_price_id = license_price_id;
    this.licence_quantity = licence_quantity;
  }
}
