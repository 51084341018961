<template>
  <div class="signup">
    <Header></Header>

    <v-container class="my-3 su-form">
      <template v-if="!loaderView && !accountSignup">
        <v-row>
          <v-col cols="12" sm="6" md="4">
           
            <v-card class="mx-auto my-6" max-width="374">
              <v-img height="200" src="/assets/images/header_md.jpg"></v-img>

              <v-card-title>We've got you covered</v-card-title>

              <v-card-text>
                <div>$800.00 USD | $1,100.00 CAD</div>
              </v-card-text>
              <v-divider class="mx-4"></v-divider>
              <v-card-text>
                <v-list-item>
                  <v-list-item-icon class="mr-3">
                    <v-icon>mdi-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <h6 class="text-h6 mb-2">Expert Guidance</h6>
                    <p class="body-2">
                      Tap into the expertise of our team of Health and Safety
                      experts and Industrial Hygienists, plus curated content
                      from scientists, infection control experts, and government
                      health officials.
                    </p>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-icon class="mr-3">
                    <v-icon>mdi-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <h6 class="text-h6 mb-2">Instant Insights</h6>
                    <p class="body-2">
                      Receive a risk score reflecting the immediate risk to each
                      team with your Exposure Control Plan(s). Learn what steps
                      you can take to reduce infection risk and identify where
                      attention and resources are needed.
                    </p>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-icon class="mr-3">
                    <v-icon>mdi-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <h6 class="text-h6 mb-2">Detailed Action Plan</h6>
                    <p class="body-2">
                      Get an extensive risk breakdown, which details risk by
                      department, location, and time period. You can share these
                      individualized reports (including specific controls) with
                      the respective teams.
                    </p>
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" sm="6" md="8">
            <v-form
              class="su-form elevation-16"
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-row>
                <v-col cols="12" v-if="message">
                  <v-alert type="info" class="my-0">{{ message }}</v-alert>
                </v-col>
                <v-col cols="12" sm="9">
                  <h3 class="text-h3 mb-3">Create your account</h3>
                </v-col>
                <v-col cols="12" sm="3">
                  <p class="overline text-right">* Required</p>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <h5 class="text-h5 mb-3">Account Information</h5>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-select
                    id="su_accountlicense"
                    v-model="user.license"
                    :items="accountLicense"
                    label="Account license *"
                    placeholder="Select..."
                    @input="updateUser(user.license)"
                    :rules="[v => !!v || 'Account license is required']"
                    required
                    filled
                  ></v-select>
                </v-col>
                <v-col cols="12" class="py-0" v-if="availableMultipleLicenses">
                  <v-select
                    id="su_accountlicamount"
                    v-model="user.licensesAmount"
                    :items="licensesAmount"
                    label="Number of licenses (including yours)"
                    placeholder="Select..."
                    :rules="[v => !!v || 'Number of licenses is required']"
                    required
                    filled
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field
                    id="su_firstname"
                    v-model="user.firstname"
                    :rules="[v => !!v || 'First name is required']"
                    label="First Name *"
                    required
                    filled
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field
                    id="su_lastname"
                    v-model="user.lastname"
                    :rules="[v => !!v || 'Last name is required']"
                    label="Last Name *"
                    required
                    filled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field
                    id="login_email"
                    v-model="user.email"
                    :rules="emailRules"
                    label="E-mail *"
                    hint="This Email will be used to log into your CORA account"
                    required
                    filled
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field
                    id="login_password"
                    v-model="user.password"
                    :rules="passwordRules"
                    label="Create a password *"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    hint="Password must contain at least 8 characters and will be used to log into your CORA account"
                    @click:append="show = !show"
                    required
                    counter
                    filled
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-if="showAddons"
                    v-model="user.addons"
                    :items="addons"
                    item-text="name"
                    item-value="id"
                    filled
                    chips
                    label="Addons"
                    multiple
                  ></v-select>
                </v-col>
              </v-row>

              <h5 class="text-h5 mb-3 mt-8">Company Information</h5>

              <v-row>
                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field
                    id="su_company"
                    v-model="user.company"
                    label="Company Name *"
                    :rules="[v => !!v || 'Company Name is required']"
                    filled
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field
                    id="su_phone"
                    v-model="user.phone"
                    label="Phone Number"
                    filled
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field
                    id="su_address"
                    v-model="user.address"
                    label="Address Line"
                    filled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field
                    id="su_city"
                    v-model="user.city"
                    label="City"
                    filled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field
                    id="su_state"
                    v-model="user.state"
                    label="State/Province/Region"
                    filled
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field
                    id="su_zip"
                    v-model="user.zip"
                    label="ZIP / Postal code"
                    filled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-autocomplete
                    ref="country"
                    v-model="user.country"
                    :items="countries"
                    label="Country"
                    placeholder="Select..."
                    filled
                    autocomplete="disabled"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field
                    id="su_referral"
                    v-model="user.referral"
                    label="How did you hear about us?"
                    filled
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row v-if="licensePrice">
                <v-col cols="12" class="py-0">
                  <p>
                    Currently, we can only accept payments in USD or CAD. Please
                    select your preferred currency.
                  </p>
                  <v-radio-group v-model="currency" row>
                    <v-radio label="USD" value="USD"></v-radio>
                    <v-radio label="CAD" value="CAD"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="7">
                  <vue-recaptcha
                    sitekey="6Ldk6dEZAAAAAInRx5exXpmHAvBGnE7PZTvB_E4B"
                    :loadRecaptchaScript="true"
                  >
                    <v-btn
                      rounded
                      :id="'checkout-button-price_' + productId"
                      color="secondary"
                      @click="handleSignup"
                      class="btn-login"
                      :disabled="!valid"
                    >
                      <template v-if="loading">Loading...</template>
                      <template v-else
                        >{{ licensePrice | priceFilter }}
                        {{ licensePrice ? this.currency : "" }}</template
                      >
                    </v-btn>
                  </vue-recaptcha>
                </v-col>
                <v-col cols="12" md="5">
                  <div class="stripe-cards">
                    <img
                      src="/assets/images/american-express@2x.png"
                      class="mx-1"
                    />
                    <img src="/assets/images/discover@2x.png" class="mx-1" />
                    <img
                      src="/assets/images/InteracLogo.svg.png"
                      class="mx-1"
                    />
                    <img src="/assets/images/JCB_logo.svg.png" class="mx-1" />
                    <img src="/assets/images/mastercard@2x.png" class="mx-1" />
                    <img src="/assets/images/visa-1@2x.png" class="mx-1" />
                  </div>
                </v-col>
                <v-col cols="12" v-if="stripeError || apiError">
                  <v-alert v-if="stripeError" type="error" class="mt-5">{{
                    stripeError
                  }}</v-alert>
                  <v-alert v-if="apiError" type="error" class="mt-5">
                    {{ apiError }}
                  </v-alert>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </template>
      <template v-else-if="!loaderView && accountSignup">
        <v-row>
          <v-col cols="12">
            <h1 class="text-h2">You received an account access to CORA</h1>
            <v-divider class="mt-8 mb-5"></v-divider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="8">
            <v-form
              class="su-form elevation-16"
              ref="form"
              v-model="validSecondForm"
              lazy-validation
            >
              <v-row>
                <v-col cols="12" v-if="message">
                  <v-alert type="info" class="my-0">{{ message }}</v-alert>
                </v-col>
                <v-col cols="12" sm="9">
                  <h3 class="text-h3 mb-3">
                    Please complete the following information
                  </h3>
                </v-col>
                <v-col cols="12" sm="3">
                  <p class="overline text-right">* Required</p>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <h5 class="text-h5 mb-3">Account Information</h5>
                </v-col>

                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field
                    id="acc_firstname"
                    v-model="acc_user.first_name"
                    :rules="[v => !!v || 'First name is required']"
                    label="First Name *"
                    required
                    filled
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field
                    id="acc_lastname"
                    v-model="acc_user.last_name"
                    :rules="[v => !!v || 'Last name is required']"
                    label="Last Name *"
                    required
                    filled
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field
                    id="acc_phone"
                    v-model="acc_user.phone"
                    label="Phone Number"
                    filled
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field
                    id="acc_address"
                    v-model="acc_user.address"
                    label="Address Line"
                    filled
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field
                    id="acc_login_email"
                    v-model="acc_user.email"
                    disabled
                    :rules="emailRules"
                    label="E-mail *"
                    hint="This Email will be used to log into your CORA account"
                    required
                    filled
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field
                    id="acc_login_password"
                    v-model="acc_user.password"
                    :rules="passwordRules"
                    label="Create a password *"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    hint="Password must contain at least 8 characters and will be used to log into your CORA account"
                    @click:append="show = !show"
                    required
                    counter
                    filled
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="7">
            <vue-recaptcha
              sitekey="6Ldk6dEZAAAAAInRx5exXpmHAvBGnE7PZTvB_E4B"
              :loadRecaptchaScript="true"
            >
              <v-btn
                @click="createLicenseAcount()"
                rounded
                id="acc_createButton"
                color="secondary"
                class="btn-login"
                :disabled="!validSecondForm"
              >
                <template v-if="loading">Loading...</template>
                <template v-else>Create account</template>
              </v-btn>
            </vue-recaptcha>
          </v-col>
          <v-col cols="12" v-if="apiError">
            <v-alert v-if="apiError" type="error" class="mt-5">
              {{ apiError }}
            </v-alert>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <!-- loading -->
        <v-col cols="12">
          <v-progress-linear
            color="secondary"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </template>
    </v-container>
  </div>
</template>
<script>
// @ is an alias to /src
import Header from "@/components/Header";
import Account from "../models/account";
import SignupService from "@/services/signup.service";
import VueRecaptcha from "vue-recaptcha";
import LicenseService from "@/services/license.service";

export default {
  components: {
    Header,
    VueRecaptcha
  },
  data() {
    return {
      loaderView: true,
      loading: false,
      user: new Account(
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        ""
      ),
      acc_user: {
        first_name: "",
        last_name: "",
        phone: "",
        address: "",
        email: "",
        password: ""
      },
      stripeError: "",
      validSecondForm: true,
      valid: true,
      message: "",
      currency: "USD",
      accountLicense: [],
      licensesData: [],
      addons: [],
      allAddons: [],
      showAddons: false,
      countries: [
        "Afghanistan",
        "Albania",
        "Algeria",
        "American Samoa",
        "Andorra",
        "Angola",
        "Anguilla",
        "Antarctica",
        "Antigua and Barbuda",
        "Argentina",
        "Armenia",
        "Aruba",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas (the)",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bermuda",
        "Bhutan",
        "Bolivia (Plurinational State of)",
        "Bonaire, Sint Eustatius and Saba",
        "Bosnia and Herzegovina",
        "Botswana",
        "Bouvet Island",
        "Brazil",
        "British Indian Ocean Territory (the)",
        "Brunei Darussalam",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cabo Verde",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Cayman Islands (the)",
        "Central African Republic (the)",
        "Chad",
        "Chile",
        "China",
        "Christmas Island",
        "Cocos (Keeling) Islands (the)",
        "Colombia",
        "Comoros (the)",
        "Congo (the Democratic Republic of the)",
        "Congo (the)",
        "Cook Islands (the)",
        "Costa Rica",
        "Croatia",
        "Cuba",
        "Curaçao",
        "Cyprus",
        "Czechia",
        "Côte d'Ivoire",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic (the)",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Eswatini",
        "Ethiopia",
        "Falkland Islands (the) [Malvinas]",
        "Faroe Islands (the)",
        "Fiji",
        "Finland",
        "France",
        "French Guiana",
        "French Polynesia",
        "French Southern Territories (the)",
        "Gabon",
        "Gambia (the)",
        "Georgia",
        "Germany",
        "Ghana",
        "Gibraltar",
        "Greece",
        "Greenland",
        "Grenada",
        "Guadeloupe",
        "Guam",
        "Guatemala",
        "Guernsey",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Heard Island and McDonald Islands",
        "Holy See (the)",
        "Honduras",
        "Hong Kong",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran (Islamic Republic of)",
        "Iraq",
        "Ireland",
        "Isle of Man",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jersey",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Korea (the Democratic People's Republic of)",
        "Korea (the Republic of)",
        "Kuwait",
        "Kyrgyzstan",
        "Lao People's Democratic Republic (the)",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macao",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands (the)",
        "Martinique",
        "Mauritania",
        "Mauritius",
        "Mayotte",
        "Mexico",
        "Micronesia (Federated States of)",
        "Moldova (the Republic of)",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Montserrat",
        "Morocco",
        "Mozambique",
        "Myanmar",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands (the)",
        "New Caledonia",
        "New Zealand",
        "Nicaragua",
        "Niger (the)",
        "Nigeria",
        "Niue",
        "Norfolk Island",
        "Northern Mariana Islands (the)",
        "Norway",
        "Oman",
        "Pakistan",
        "Palau",
        "Palestine, State of",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines (the)",
        "Pitcairn",
        "Poland",
        "Portugal",
        "Puerto Rico",
        "Qatar",
        "Republic of North Macedonia",
        "Romania",
        "Russian Federation (the)",
        "Rwanda",
        "Réunion",
        "Saint Barthélemy",
        "Saint Helena, Ascension and Tristan da Cunha",
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Martin (French part)",
        "Saint Pierre and Miquelon",
        "Saint Vincent and the Grenadines",
        "Samoa",
        "San Marino",
        "Sao Tome and Principe",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Sint Maarten (Dutch part)",
        "Slovakia",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "South Africa",
        "South Georgia and the South Sandwich Islands",
        "South Sudan",
        "Spain",
        "Sri Lanka",
        "Sudan (the)",
        "Suriname",
        "Svalbard and Jan Mayen",
        "Sweden",
        "Switzerland",
        "Syrian Arab Republic",
        "Taiwan",
        "Tajikistan",
        "Tanzania, United Republic of",
        "Thailand",
        "Timor-Leste",
        "Togo",
        "Tokelau",
        "Tonga",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Turks and Caicos Islands (the)",
        "Tuvalu",
        "Uganda",
        "Ukraine",
        "United Arab Emirates (the)",
        "United Kingdom of Great Britain and Northern Ireland (the)",
        "United States Minor Outlying Islands (the)",
        "United States of America (the)",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Venezuela (Bolivarian Republic of)",
        "Viet Nam",
        "Virgin Islands (British)",
        "Virgin Islands (U.S.)",
        "Wallis and Futuna",
        "Western Sahara",
        "Yemen",
        "Zambia",
        "Zimbabwe",
        "Åland Islands"
      ],
      emailRules: [
        v => !!v || "E-mail is required",
        v => /.+@.+\..+/.test(v) || "E-mail must be valid"
        // (v) => (v && this.doesUserExist != true) || "Email already exists.",
      ],
      passwordRules: [
        v => !!v || "Password is required",
        v => v.length >= 8 || "Min 8 characters"
      ],
      show: false,
      apiError: "",
      publishableKey: process.env.VUE_APP_STRIPE_KEY,
      accountSignup: false
    };
  },
  created() {
    var urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has("session_id")) {
      this.message = "Your payment was cancelled.";
    }
  },

  filters: {
    priceFilter: function(value) {
      if (value) {
        return (
          "PAY " +
          value.toLocaleString("en-US", {
            style: "currency",
            currency: "USD"
          })
        );
      } else {
        return "Send Request";
      }
    }
  },

  computed: {
    productId() {
      if (this.user?.license && this.licensePrice) {
        return this.licensesData
          .find(e => e.name === this.user.license)
          .prices.find(i => i.currency === this.currency)[
          process.env.VUE_APP_STRIPE_PAYMENT_ID
        ];
      } else {
        return "";
      }
    },

    licensesAmount() {
      if (this.user?.license) {
        return [
          ...Array(
            this.licensesData.find(e => e.name === this.user.license)
              .license_amount + 1
          ).keys()
        ].slice(1);
      } else {
        return false;
      }
    },

    availableMultipleLicenses() {
      if (this.user?.license) {
        return (
          this.licensesData.find(e => e.name === this.user.license)
            .license_amount > 1
        );
      } else {
        return false;
      }
    },

    licensePrice() {
      if (this.user?.license) {
        if (
          this.licensesData.find(e => e.name === this.user.license).prices
            .length
        ) {
          return (
            this.licensesData
              .find(e => e.name === this.user.license)
              .prices.find(i => i.currency === this.currency).amount *
            this.user.licensesAmount
          );
        } else {
          return false;
        }
      } else {
        return "-";
      }
    }
  },

  mounted() {
    if (this.$route.query.token && this.$route.query.token.length > 1) {
      this.accountSignup = true;
      this.getUserData();
    } else {
      this.getLicenses();
    }
  },

  methods: {
    async createLicenseAcount() {
      this.loaderView = true;
      const isValid = this.$refs.form.validate();
      if (isValid) {
        try {
          this.acc_user.invite_token = this.$route.query.token;
          await LicenseService.createAccByLicense({
            data: this.acc_user
          });
          this.loaderView = false;
          this.$router.push("/thankyou");
        } catch (e) {
          console.log(e);
          this.loading = false;
          this.loaderView = false;
        }
      }
    },

    async getUserData() {
      const userData = await LicenseService.getInvitationData({
        token: this.$route.query.token
      });
      this.acc_user.first_name = userData.data.data[0].granted_first_name;
      this.acc_user.last_name = userData.data.data[0].granted_last_name;
      this.acc_user.email = userData.data.data[0].granted_email;
      this.loaderView = false;
    },

    async getLicenses() {
      const response = await SignupService.getlicense();
      response.data.data.forEach(e => {
        this.accountLicense.push(e.name);
        this.licensesData.push({
          name: e.name,
          roles: e.roles,
          prices: e.prices,
          license_id: e.id,
          license_amount: e.purchase_limit
        });
        this.allAddons[e.id] = e.addons;
      });
      this.loaderView = false;
    },

    updateUser() {
      this.user.license_id = this.licensesData.find(
        e => e.name === this.user.license
      ).license_id;
      if (
        this.licensesData.find(e => e.name === this.user.license).roles.length
      ) {
        this.user.type = this.licensesData.find(
          e => e.name === this.user.license
        ).roles[0].name;
      } else {
        this.user.type = "Standard";
      }
      this.addons = this.allAddons[this.user.license_id];
      this.showAddons = true;
    },

    async handleSignup() {
      const isValid = this.$refs.form.validate();

      if (isValid) {
        this.loading = true;

        try {
          const data = {
            email: this.user.email,
            password: this.user.password,
            license: this.user.license,
            type: this.user.type,
            first_name: this.user.firstname,
            last_name: this.user.lastname,
            address: this.user.address,
            city: this.user.city,
            state: this.user.state,
            zip: this.user.zip,
            country: this.user.country,
            company: this.user.company,
            phone: this.user.phone,
            referred_by_email: this.user.referral,
            license_id: this.user.license_id,
            license_price_id: this.licensePrice
              ? this.licensesData
                  .find(e => e.name === this.user.license)
                  .prices.find(i => i.currency === this.currency).id
              : null,
            licence_quantity: this.user.licensesAmount,
            addons: this.user.addons
          };
          console.log("signup data", data);
          await SignupService.newSignup({
            data: data
          });

          if (this.licensePrice) {
            ////
            //TODO #371 - here we need to catch (before stripe) if the email already exists
            ////


            //STRIPE
            var stripe = window.Stripe(this.publishableKey);
            var DOMAIN = location.href.replace(/[^/]*$/, "");

            stripe
              .redirectToCheckout({
                lineItems: [
                  { price: this.productId, quantity: this.user.licensesAmount }
                ],
                mode: "subscription",
                // Do not rely on the redirect to the successUrl for fulfilling
                // purchases, customers may not always reach the success_url after
                // a successful payment.
                // Instead use one of the strategies described in
                // https://stripe.com/docs/payments/checkout/fulfill-orders
                successUrl:
                  DOMAIN + "thankyou?session_id={CHECKOUT_SESSION_ID}",
                cancelUrl: DOMAIN + "signup?session_id={CHECKOUT_SESSION_ID}",

                customerEmail: this.user.email
              })
              .then(function(result) {
                if (result.error) {
                  // If `redirectToCheckout` fails due to a browser or network
                  // error, display the localized error message to your customer.
                  // var displayError = document.getElementById("error-message");
                  // displayError.textContent = result.error.message;

                  this.stripeError = result.error.message;
                }
              });
          } else {
            this.$router.push("/thankyou?=enterprise");
          }
        } catch (e) {
          this.apiError = e.response.data.message;
          this.loading = false;
        }
      } else {
        this.apiError =
          "There are items that require attention. Please fix before trying again.";
      }
    }
  }
};
</script>
