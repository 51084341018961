<template>
  <div class="thanks">
    <Header></Header>

    <v-container class="my-3">
      <v-row>
        <v-col cols="12">
          <h1 class="text-h2">{{ pageTitle }}</h1>
          <v-divider class="mt-8 mb-5"></v-divider>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="7">
          <template v-if="etpAccountCreated">
            <v-icon class="mb-4 d-flex" style="color: #00c853; font-size: 24px;"
              >mdi-check-circle-outline</v-icon
            >
            <h3 class="mb-4">
              We will get in touch with you to complete your account setup and
              activation process
            </h3>
          </template>

          <p class="body-2">
            Thank you for signing up. We look forward to helping you
            access the latest information, insights, and planning tips to help
            your business minimize the risk for employees and the
            public.
          </p>

          <template v-if="!etpAccountCreated">
            <p class="body-2">
              You can now access using the email and password you set up
              during sign up. If you have any issues signing in, please
              <a href="mailto:support@mycoraapp.com"
                >contact our support team</a
              >
              who will be happy to help.
            </p>

            <p>
              <v-btn to="/login" rounded color="secondary">
                <v-icon left>mdi-fingerprint</v-icon>Login
              </v-btn>
            </p>
          </template>
        </v-col>

        <v-col cols="12" sm="5">
          <v-img
            src="/assets/images/camera_md.jpg"
            srcset="/assets/images/camera_lg.jpg 1024w, /assets/images/camera_md.jpg 640w, /assets/images/camera_sm.jpg 320w"
            aspect-ratio="1"
            class="grey lighten-2"
            max-width="500"
            max-height="240"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
// @ is an alias to /src
import Header from "@/components/Header";

export default {
  name: "Thankyou",
  components: {
    Header
  },
  data() {
    return {
      etpAccountCreated: false,
      pageTitle: "Thank you for signing up to CORA"
    };
  },
  created() {
    var urlParams = new URLSearchParams(window.location.search);

    this.etpAccountCreated = typeof this.$route.query.etp !== "undefined";

    if (urlParams.has("session_id")) {
      this.pageTitle = "Your payment was successful";
    }
  }
};
</script>
