<template>
  <div class="payment">
    <Header></Header>

    <v-container class="my-3 su-form">
      <v-row>
        <v-col cols="12">
          <h1 class="text-h2">Make payment to complete your account set-up</h1>
          <v-divider class="mt-8 mb-5"></v-divider>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="4">
          <p class="body-1">Creation of a single-site COVID-19 plan</p>
          <p class="body-1">${{ amount / 100 }} USD</p>
        </v-col>

        <v-col cols="12" sm="8">
          <h3>Pay by card</h3>
          <stripe-elements
            ref="elementsRef"
            :pk="publishableKey"
            :amount="amount"
            locale="us"
            @token="tokenCreated"
            @loading="loading = $event"
            class="mt-3"
          >
          </stripe-elements>

          <v-btn rounded color="primary" @click="submit" class="btn-submit"
            >Pay ${{ amount / 100 }}</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
// @ is an alias to /src
import { StripeElements } from "vue-stripe-checkout";
import Header from "@/components/Header";

export default {
  components: {
    Header,
    StripeElements
  },

  data: () => ({
    loading: false,
    amount: 75000,
    publishableKey: process.env.VUE_APP_STRIPE_KEY,
    token: null,
    charge: null
  }),

  methods: {
    submit() {
      this.$refs.elementsRef.submit();
    },
    tokenCreated(token) {
      this.token = token;
      // for additional charge objects go to https://stripe.com/docs/api/charges/object
      this.charge = {
        source: token.id,
        amount: this.amount, // the amount you want to charge the customer in cents. $100 is 1000 (it is strongly recommended you use a product id and quantity and get calculate this on the backend to avoid people manipulating the cost)
        description: this.description // optional description that will show up on stripe when looking at payments
      };
      this.sendTokenToServer(this.charge);
    },
    sendTokenToServer(charge) {
      // Send to charge to your backend server to be processed
      // Documentation here: https://stripe.com/docs/api/charges/create
      console.log("charge", charge);
    }
  }
};
</script>
